/**
 * global Tabs Script
 */

/* eslint-disable */
import Tabby from 'tabbyjs';

if(document.querySelectorAll('[data-tabs-h-one]').length > 0) {
    const tabs1 = new Tabby('[data-tabs-h-one]');
}

if(document.querySelectorAll('[data-tabs-h-two]').length > 0) {
    const tabs2 = new Tabby('[data-tabs-h-two]');
}
/* eslint-enable */
