/**
 * header
 */

import Headroom from '../../../../node_modules/headroom.js/dist/headroom';

const header = {
    cfg:  {
        header: '.js-pageHeader',
        class: '.js-pageHeader',
        headerOptions: {
            // vertical offset in px before element is first unpinned
            offset : 200,
            // scroll tolerance in px before state changes
            // tolerance : 0,
            // or scroll tolerance per direction
            tolerance : {
                down : 0,
                up : 0
            },
            classes : {
                // when element is initialised
                initial : 'is-animated',
                // when scrolling up
                pinned : 'is-down',
                // when scrolling down
                unpinned : 'is-up',
                // when above offset
                top : 'is-top',
                // when below offset
                notTop : 'is-notTop',
                // when at bottom of scoll area
                bottom : 'is-bottom',
                // when not at bottom of scroll area
                notBottom : 'is-notBottom',
            },
        }
    },

    init() {
        // grab an element
        const stickyHeader = document.querySelector(this.cfg.header);
        // construct an instance of Headroom, passing the element
        const headroom  = new Headroom(stickyHeader, this.cfg.headerOptions);
        // initialise
        headroom.init();
    }
};

export default header;
