/**
 * Cover
 */

import TweenMax from 'gsap/src/uncompressed/TweenMax';

const cover = {
    cfg: {
        slider: '.js-cover',
    },

    init() {
        window.addEventListener('load', () => {
            const sliders = [...document.querySelectorAll(this.cfg.slider)];
            if (sliders) {
                sliders.forEach((slider) => {
                    let imgs = slider.children;
                    imgs = Array.from(imgs);

                    if (imgs.length > 1) {
                        imgs.reverse();

                        setInterval(()=>{
                            TweenMax.to(imgs[0], 1, {autoAlpha:0});
                            TweenMax.to(imgs[1], 1, {autoAlpha:1});
                            imgs.push(imgs.shift());
                        },3000);
                    }

                    // const flkty = new Flickity(slider, this.cfg.sliderOptions);
                    // flkty.resize();

                    // setTimeout(() => {
                    //     flkty.resize();
                    //     slider.classList.add('is-ready');
                    // }, 1000);
                });
            }
        });
    }
};

export default cover;
