/**
 * objects
 */

import $ from 'jquery';

const objects = {
    cfg:  {
        name: 'objects',
        selectors: {
            objects: 'o-objects',
        }
    },
    init() {
        // eslint-disable-next-line
        const object_data = [];
        $('.a-house .a-house__apartment ').on('mouseover touchstart touchend', function dynPopOver(event) {
            if (event.preventDefault) event.preventDefault();
            // eslint-disable-next-line
            object_data.title = $(this).data('number');
            object_data.rooms = $(this).data('rooms');
            object_data.size = $(this).data('size');
            object_data.rentCold = $(this).data('rentcold');
            object_data.addCosts = $(this).data('addcosts');
            object_data.status = $(this).data('status');
            let blockRent = false;

            switch (object_data.status) {
                case 'free':
                    object_data.status = 'zu vermieten';
                    blockRent = false;
                    break;
                case 'reserved':
                    object_data.status = 'reserviert';
                    blockRent = true;
                    break;
                case 'rented':
                    object_data.status = 'vermietet';
                    blockRent = true;
                    break;
                default:
                    object_data.status = '—';
                    blockRent = false;
            }

            if (!blockRent && (object_data.rentCold && object_data.addCosts)) {
                object_data.rentWarm = parseInt(object_data.rentCold.replace('.', ''), 10) + parseInt(object_data.addCosts.replace('.', ''), 10);
                // eslint-disable-next-line
                $('<div class="a-dynamicPopover"><table>' +
                '<thead><tr><th colspan="2">Wohnung – <span>' + object_data.title + '<span></th><tr></thead>' +
                '<tbody>' +
                '<tr><td>Status:</td><td><b>' + object_data.status + '</b></td></tr>' +
                '<tr><td>Größe:</td><td><b>' + object_data.size + ' m²</b></td></tr>' +
                '<tr><td>Zimmer:</td><td><b>' + object_data.rooms + '</b></td></tr>' +
                '<tr><td>Kaltmiete:</td><td><b>' + object_data.rentCold + ' €</b></td></tr>'+
                '<tr><td>Nebenkosten:</td><td><b>' + object_data.addCosts +  ' €</b></td></tr>' +
                '<tr><td>Warmmiete:</td><td><b>' + object_data.rentWarm.toLocaleString('de-DE', {minimumFractionDigits: 2})+ ' €</b></td></tr>' +
                '</tbody>' +
                '</table></div>'
                ).appendTo('body');
            } else {
                // eslint-disable-next-line
                $('<div class="a-dynamicPopover"><table>' +
                '<thead><tr><th colspan="2">Wohnung – <span>' + object_data.title + '<span></th><tr></thead>' +
                '<tbody>' +
                '<tr><td>Status:</td><td><b>' + object_data.status + '</b></td></tr>' +
                '<tr><td>Größe:</td><td><b>' + object_data.size + ' m²</b></td></tr>' +
                '<tr><td>Zimmer:</td><td><b>' + object_data.rooms + '</b></td></tr>' +
                '</tbody>' +
                '</table></div>'
                ).appendTo('body');
            }
        }).on('mouseleave touchleave', () =>{
            $('.a-dynamicPopover').remove();
        }).on('mousemove touchstart touchend', (e) => {
            let mouseX;
            let mouseY;

            if (e.pageX || e.pageY) {
                mouseX = e.pageX; 
                mouseY = e.pageY; 
            } else {
                mouseX = e.originalEvent.changedTouches[0].pageX;
                mouseY = e.originalEvent.changedTouches[0].pageY;
            }

            $('.a-dynamicPopover').css({
                top: mouseY - ($('.a-dynamicPopover').height() + 30),
                left: mouseX - ($('.a-dynamicPopover').width() / 2),
            });
        });
    }
};


export default objects;
