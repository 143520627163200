/**
 * parallax
 */
import SimpleParallax from 'simple-parallax-js';

const parallax = {
    init() {
        window.addEventListener('load', () => {
            const images = document.querySelectorAll('.image-parallax img');
            if (images.length) {
                new SimpleParallax(images, {
                    delay: 0,
                    orientation: 'up',
                    scale: 1.3,
                    overfow: false
                });
            }
        });
    }
};

export default parallax;
