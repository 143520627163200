/**
 * modules
 */

// import preloader from        '../../../templates/_atoms/preloader/_script';
import input from               '../../../templates/_atoms/input/_script';
import textarea from            '../../../templates/_atoms/textarea/_script';
import checkbox from            '../../../templates/_atoms/checkbox/_script';
import accordion    from        '../../../templates/_organisms/accordion/_script';
import gridOverlay    from      '../../../templates/_partials/gridOverlay/_script';
// import cookiebar    from     '../../../templates/_components/cookiebar/_script';
import cover    from     '../../../templates/_organisms/pageCover/_script';
import imageSlider  from     '../../../templates/_organisms/imageSlider/_script';
import imageGallery from     '../../../templates/_organisms/imageGallery/_script';
import toggle from     '../../../templates/_molecules/toggle/_script';
import pageHeader from     '../../../templates/_organisms/pageHeader/_script';
import objects from     '../../../templates/_organisms/objects-gehlsdorf/_script';
// import contactForm from     '../../../templates/_organisms/contactForm/_script';

// Modules Array
const modulesArray = [
    imageSlider,
    imageGallery,
    toggle,
    input,
    textarea,
    checkbox,
    gridOverlay,
    accordion,
    pageHeader,
    cover,
    objects
    // contactForm,
];

const modules = {
    // Modules
    Modules() {
        if (modulesArray.length > 0 && modulesArray !== undefined) {
            modulesArray.forEach((module) => {
                module.init();
            });
        }
    },

    init() {
        this.Modules();
    }
};

export default modules;
